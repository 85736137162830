import React, { useEffect, useState } from "react";
import styles from "./Orders.module.css";
import SearchSvg from "../../assets/Search.svg";
import FilterSvg from "../../assets/Filter.svg";
import AmazonaSvg from "../../assets/amazona.svg";
import itemOneSvg from "../../assets/itemOne.svg";
import itemTwoSvg from "../../assets/itemTwo.svg";
import itemThreeSvg from "../../assets/itemThree.svg";
import DHLSvg from "../../assets/dhl.svg";
import apiServiceHandler from "../../service/apiService";
import Spinner from "../Spinner/Spinner";

const Orders = () => {
  const [orders, setOrders] = useState([]); // State to store the list of orders
  const [loading, setLoading] = useState(true); // State to track loading status

  // Fetch orders from the API on component mount
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await apiServiceHandler("GET", "api/order/list");
        if (response.status) {
          setOrders(response.orders); // Set the orders data to state
        } else {
          alert("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <Spinner/>; 
  }

  return (
    <div className={styles.orders}>
      <div className={styles.orders_container}>
        <div className={styles.orders_info}>
          <div className={styles.orders_header}>
            <div className={styles.header_left}>
              <h4>Orders</h4>
              <p>Access all the orders you have placed</p>
            </div>
            <div className={styles.header_right}>
              <div className={styles.inputWrapper}>
                <img
                  src={SearchSvg}
                  alt="search icon"
                  className={styles.searchIcon}
                />
                <input
                  className={styles.input}
                  placeholder="Search by order name"
                />
                <img
                  src={FilterSvg}
                  alt="Filter icon"
                  className={styles.filterIcon}
                />
              </div>
            </div>
          </div>

          <div className={styles.orders_list}>
            {orders.map((order) => (
              <div className={styles.order_info} key={order._id}>
                <div className={styles.info_header}>
                  <div className={styles.info_header_left}>
                    {/* <img src={AmazonaSvg} alt="Amazona Store" /> */}
                    <div className={styles.info_header_left_info}>
                      <div className={styles.info_header_left_top}>
                        <span className={styles.total_price}>
                          {/* $11,999.99  */}
                        </span>
                        <span className={styles.header_info_tag}>
                          PROCESSING
                        </span>
                      </div>
                      {/* <div>Amazon Store</div> */}
                    </div>
                  </div>
                  <div className={styles.info_header_right}>
                    <div className={styles.total_items}>
                      {order.products.length} items
                    </div>
                    {/* <div className={styles.items_images}>
                      {order.products.slice(0, 3).map((product, index) => (
                        <img key={index} src={itemOneSvg} alt={`item ${index + 1}`} />
                      ))}
                    </div> */}
                  </div>
                </div>
                <div className={styles.order_details}>
                  <div className={styles.order_details_left}>
                    <div className={styles.order_number_container}>
                      <div className={styles.order_number_heading}>
                        Order Number
                      </div>
                      <div className={styles.order_number}>
                        {order.order_number}
                      </div>
                    </div>

                    {/* Display any relevant shipping address or info if needed */}
                    <div className={styles.order_receiver_container}>
                      <div className={styles.order_receiver_heading}>
                        Receiver
                      </div>
                      <div className={styles.order_receiver}>
                          {order.address.name}
                        {/* Bansangu-12, Priyankamandir, Makwanpur 45612  */}
                      </div>
                    </div>

                    <div className={styles.order_eta_container}>
                      <div className={styles.order_eta_heading}>
                        Estimated delivery time
                      </div>
                      <div className={styles.order_eta}>
                        7 business days after the store confirms the order
                      </div>
                    </div>
                  </div>

                  <div className={styles.order_details_right}>
                    <div className={styles.order_service_container}>
                      <div className={styles.order_service_heading}>
                        Delivery Service
                      </div>
                      <div className={styles.order_service}>
                        {/* <img src={DHLSvg} alt="DHL" /> */}
                        <span>--</span>
                        {/* <span>DHL</span> */}
                      </div>
                    </div>

                    <div className={styles.order_courier_container}>
                      <div className={styles.order_courier_heading}>
                        Courier delivery to
                      </div>
                      <div className={styles.order_courier}>
                          <p>{order.address.address},</p>
                          <p>{order.address.city},{order.address.zip_code}</p>
                          <p>{order.address.country}</p>
                        {/* Bansangu-12, Priyankamandir, Makwanpur 45612  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
