import React, { useEffect, useState } from "react";
// import styles from "../BulkOrder/BulkOrder.module.css"; 
import styled from "styled-components";
import { Autocomplete, TextField } from "@mui/material";
import LoginModal from "./LoginStep/LoginStep";
import styles from './PlaceOrder.module.css'
import OrderStep from "./OrderStep/OrderStep";
import AdressStep from "./AdressStep/AdressStep";
import PaymentStep from "./PaymentStep/PaymentStep";
import SuccessStep from "./SuccessStep/SuccessStep";

// Styled component for consistent text fields
export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#9ca3af",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      border: "0.8px solid #374151",
    },
  },
});

const PlaceOrderModal = ({ isVisible, onClose }) => {
  const BHARAT_TOKEN = localStorage.getItem('BHARAT_TOKEN') || ''
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentStep, setCurrentStep] = useState(BHARAT_TOKEN ? 'order' : 'login');
  const [formData, setFormData] = useState({
    orderDetails: '',
    address: '',
    paymentMethod: '',
  });
 
  useEffect(() => {
    if (BHARAT_TOKEN) {
      setIsLoggedIn(true)
    } 
  }, [currentStep])

  const [selectedPayment, setSelectedPayment] = useState(null); // Track selected payment

  if (!isVisible) return null;


  const handleOrderSubmit = () => {
    setCurrentStep('address');
  };

  const handleAddressSubmit = () => {
    setCurrentStep('payment');
  };

  const handlePaymentSubmit = () => {
    setCurrentStep('success');
  };

  const handlePaymentSelect = (method) => {
    setSelectedPayment(method);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 'order':
        return (
          <><OrderStep onClose={onClose} handleOrderSubmit={handleOrderSubmit} setCurrentStep={setCurrentStep} /></>
        );

      case 'address':
        return (

         <> <AdressStep onClose={onClose} handleAddressSubmit={handleAddressSubmit} setCurrentStep={setCurrentStep} /></> 
        );
      case 'payment':
        return (
          <> <PaymentStep onClose={onClose} handlePaymentSubmit={handlePaymentSubmit}  selectedPayment={selectedPayment} handlePaymentSelect={handlePaymentSelect} setCurrentStep={setCurrentStep} /></>
        );
      case 'success':
        return (
        <><SuccessStep onClose={onClose}/></>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!isLoggedIn ? (
        // Show login component if the user is not logged in
        <div className={styles.modal_container}>
          <div className={styles.modal_overlay}>
          <LoginModal setCurrentStep={setCurrentStep} />
            {/* <div className={styles.modal_login}>
              <LoginModal handleLogin={handleLogin} />
            </div> */}
          </div>
        </div>
      ) : (


        // Show order process after login
        <div className={styles.modal_container}>
          <div className={styles.modal_overlay}>
            <div className={styles.modal}>
              <div className={styles.modal_body}>{renderStepContent()}</div>
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default PlaceOrderModal;
