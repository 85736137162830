import React, {forwardRef } from 'react';
import styles from './Partners.module.css'
import DHL from '../../assets/dhl1.svg'
import FEDEX from '../../assets/fedex.svg'
import DART from '../../assets/dart.svg'
import DELHIVERY from '../../assets/delhivery.svg'


const partners = [
    {
        id: 1,
        imgSrc: DHL
    },
    {
        id: 2,
        imgSrc: FEDEX
    },
    {
        id: 3,
        imgSrc: DART
    },
    {
        id: 4,
        imgSrc: DELHIVERY
    },
    {
        id: 5,
        imgSrc: DHL
    },
    {
        id: 6,
        imgSrc: FEDEX
    },
    {
        id: 7,
        imgSrc: DART
    },
    {
        id: 8,
        imgSrc: DELHIVERY
    },
]

const Partners = forwardRef((props, ref) => {
  return (
    <div className={styles.partners} ref={ref}>
        <div className={styles.partners_container}>
            <h3 className={styles.partners_heading}>Our top delivery partners</h3>
            <div className={styles.partners_list}>
                {partners.map((partner) => (
                    <div className={styles.partner_box} key={partner.id}>
                        <img src={partner.imgSrc} />
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
})

export default Partners