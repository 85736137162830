import React from "react";
import styles from "./Services.module.css";
import GroupZero from "../../assets/1000-happy-customers.jpg";
import GroupOne from '../../assets/60-orders-graphic.jpg'
import GroupTwo from "../../assets/Group2.svg";

const items = [
  {
    id: 1,
    itemSrc: GroupZero,
    itemDesc: "1000+ Happy Customers",
  },
  {
    id: 2,
    itemSrc: GroupOne,
    itemDesc: "60+ Websites to order",
  },
  {
    id: 3,
    itemSrc: GroupTwo,
    itemDesc: "Fast and trusted service",
  },
];

const Services = () => {
  return (
    <div className={styles.services}>
      <div className={styles.services_container}>
        <div className={styles.services_header}>
          <div className={styles.services_heading}>
            <h1>Why use our services?</h1>
          </div>
          <div className={styles.services_paragraph}>
            <p>Bringing you the best selection of Authentic Indian</p>
            <p> Goods and Products Delivered Right to your doorstep</p>
          </div>
        </div>
        <div className={styles.services_info}>
          {items.map((item) => (
            <div key={item.id} className={styles.service_info}>
              <img className={item.id === 3 ? styles.image_style_two : styles.image_style} src={item.itemSrc} />
              <div className={styles.service_desc}>{item.itemDesc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
