import React from "react";
import styles from "./Possible.module.css";
import StepOne from "../../assets/step1.svg";
import StepTwo from "../../assets/step2.svg";
import StepThree from "../../assets/step3.svg";
import StepFour from "../../assets/step4.svg";

const steps = [
  {
    step: 1,
    title: "1. We get the request of your favorite product",
    description:
      "We receive your requests for favorite Indian products, ensuring a smooth process to bring them directly to you with ease and convenience.",
    imgSrc: StepOne,
  },
  {
    step: 2,
    title: "2. We process the your favorite product’s request",
    description:
      "We efficiently process your request for favorite Indian products, ensuring timely handling and a smooth journey from order to delivery",
    imgSrc: StepTwo,
  },
  {
    step: 3,
    title: "3. We deliver your product with the best care",
    description:
      "We deliver your favorite Indian products with the utmost care, ensuring they reach you safely. Stay informed with timely updates at every step of the delivery process",
    imgSrc: StepThree,
  },
  {
    step: 4,
    title: "4. You get your favorite Indian product seamlessly",
    description:
      "You receive your favorite Indian products seamlessly, delivered right to your doorstep without any hassle, making your shopping experience smooth",
    imgSrc: StepFour,
  },
];

const Possible = () => {
  return (
    <div className={styles.possible}>
      <div className={styles.possible_container}>
        <h3>But how we make it possible?</h3>
        <div className={styles.possible_steps}>
          {steps.map((step) => (
            <div className={styles.step_box}>
              <div className={styles.step_left}>
                <div className={styles.box_step_number}>STEP {step.step}</div>
                <div className={styles.box_step}>{step.title}</div>
                <div className={styles.box_step_desc}>{step.description}</div>
              </div>
              <div className={styles.step_right}>
                <img src={step.imgSrc} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Possible;
