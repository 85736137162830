import React, { useState, useEffect } from "react";
import styles from "./Account.module.css";
import { TextField } from "@mui/material";
import styled from "styled-components";
import apiServiceHandler from "../../service/apiService";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2"; 
import "react-phone-input-2/lib/style.css"; 

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#9ca3af",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      border: "0.8px solid #374151",
    },
  },
});

const Account = () => {
  const [name, setName] = useState("");  // Empty initial state
  const [contactNumber, setContactNumber] = useState(""); // Empty initial state
  const [email, setEmail] = useState(""); // Empty initial state
  const [isEditing, setIsEditing] = useState(false);

  // Get the userId from localStorage
  const userId = localStorage.getItem("bharatId");

  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiServiceHandler("GET", `api/user/${userId}`);
        if (response.status) {
          const { user } = response;
          setName(user.name || "Your Name");
          setContactNumber(user.contact_number || "Your Phone");
          setEmail(user.email || "youremail@example.com");
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]); // Runs the effect when userId changes

  const handleEditSave = async () => {
    if (isEditing) {
      try {
        const payload = {
          name,
          contact_number: contactNumber,
        };

        const response = await apiServiceHandler("POST", `api/user/${userId}`, payload);

        if (response.status) {
          toast.success("User details updated successfully");
        } else {
          toast.error("Failed to update user details");
        }
      } catch (error) {
        console.error("Error updating user details:", error);
        toast.error("An error occurred while updating details");
      }
    }
    // Toggle between editing and saving modes
    setIsEditing(!isEditing);
  };

  return (
    <div className={styles.account}>
      <div className={styles.account_container}>
        <div className={styles.account_info}>
          <div className={styles.account_header}>
            <div className={styles.header_left}>
              <h4>Account Details</h4>
              <p>Your profile photo, phone number, and email</p>
            </div>
            <div className={styles.header_right}>
              <button onClick={handleEditSave}>
                {isEditing ? "SAVE DETAILS" : "EDIT DETAILS"}
              </button>
            </div>
          </div>
          <div className={styles.account_section}>
            <div className={styles.account_name}>
              <CssTextField
                className={styles.name_field}
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!isEditing}
                placeholder="Your Name"
              />
            </div>
            <div className={styles.account_contact}>
              <PhoneInput
                country={"us"}
                label="Phone"
                value={contactNumber}
                onChange={(phone) => setContactNumber(phone)}
                disabled={!isEditing}
                className={styles.input_field}
                inputStyle={{
                  height: "100%",
                  width: "100%",
                  color: isEditing ? "black" : "rgba(0, 0, 0, 0.38)",
                }}
                placeholder="Your Phone"
              />
              {/* <CssTextField
                className={styles.input_field}
                label="Phone Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                disabled={!isEditing}
                placeholder="Your Phone"
              /> */}
              <CssTextField
                className={styles.input_field}
                label="Email Address"
                value={email}
                disabled
                placeholder="youremail@example.com"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
