import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import HeaderImg from "../../assets/content.png";
import FrameImg from "../../assets/Frame.png";
import CartImg from "../../assets/Buy.png";
import ProfileImg from "../../assets/Ellipse.png";
import SearchSvg from "../../assets/Search.svg";
import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { Tooltip } from "react-tooltip";
import PlaceOrderModal from "../PlaceOrder"

const Header = ({scrollToWYCO}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [token, setToken] = useState();
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOpenModal = () => {
    setModalVisible(true);  // Show modal
  };

  const handleCloseModal = () => {
    setModalVisible(false); // Hide modal
    window.location.reload()
  };

  useEffect(() => {
    setToken(localStorage.getItem('BHARAT_TOKEN'))
  }, [])

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Navbar token={token} />
      </div>
      <div className={styles.header_content}>
        <div className={styles.header_info}>
          <div className={styles.header_main}>
            <h2>
              Shop Products from <span>INDIA</span>
            </h2>
          </div>
          <div className={styles.header_paragraph}>
            <p>
            Bringing You the Best Selection of Authentic Indian Goods and Products, Delivered Right to Your Doorstep
            </p>
          </div>
          <div className={styles.header_main_input}>
            <div className={styles.header_input}>
              {/* <input placeholder="Paste Your Link" /> */}
              <button className={styles.place_order_btn} onClick={handleOpenModal}>
                <span>PLACE ORDER</span>
              </button>
              <button className={styles.explore_btn} onClick={scrollToWYCO}>
                <span >EXPLORE</span>
              </button>
            </div>

                
            {/* <div
              className={styles.question_mark}
              id="question-mark"
              style={{ cursor: "pointer", color: "#000" }}
            >
                 <Link to="/how-it-works"><h2>?</h2> </Link>
            </div>
            {

              <Tooltip
                anchorSelect="#question-mark"
                place="top"
                className={styles.toolTip}
              >
              <div>How it really works... 
              </div>
              </Tooltip>

            } */}
          </div>
        </div>
        <div className={styles.header_img}>
          <img src={HeaderImg} alt="header image" />
        </div>
      </div>
      <PlaceOrderModal isVisible={isModalVisible} onClose={handleCloseModal} />
    </header>
  );
};

export default Header;
