import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";

const usePasswordVisible = () => {
  const [visible, setVisible] = useState(false);

  const Icon = visible ? EyeOff : Eye;

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return [visible, Icon, toggleVisibility];
};

export default usePasswordVisible;
