import React from "react";
import styles from '../PlaceOrder.module.css'
import successGif from '../../../assets/success.gif';
import { useNavigate } from "react-router-dom";


const SuccessStep = ({onClose}) => {

  const navigate = useNavigate()

  const navigateToOrders = () => {
    navigate('/profile')
    window.location.reload()
  }

  return (
  <>
    <div className={styles.modal_header} style={{float:'right'}}>
      <button onClick={onClose} >X</button>
    </div>
    <div className={styles.success_box}>
       <img src={successGif} alt="Success" />
    </div>

    <h2>Congratulations you have 
        <br></br>
        successfully placed your order</h2>
    <button className={styles.proceedBtn} onClick={navigateToOrders}>View Order Details</button>
  </>
  )
}

export default SuccessStep