import React, { useEffect } from "react";
import styles from "./About.module.css";
import AllItemsSvg from "../../assets/allSites.svg";

const About = () => {
  return (
    <div className={styles.about}>
      <div className={styles.about_container}>
        <div className={styles.about_left}>
          <h6>ABOUT US</h6>
          <h3 className={styles.about_left_heading}>
            We make possible shopping products from India 🇮🇳
          </h3>
          <div className={styles.about_left_info}>
            Get the best of India from your favorite shopping platform whether
            it is Flipkart, Amazon, Myntra or more, making every purchase
            seamless and convenient.
          </div>
          <img src={AllItemsSvg} />
        </div>
        <div className={styles.about_right}>
            <iframe
              width="600"
              height="340"
              src="https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
        </div>
      </div>
    </div>
  );
};

export default About;
