import React, { useState } from 'react';
import styles from './FloatingIcon.module.css';
import FloatingIconImg from '../../assets/online-shopping-sale.png' 
import WhatsAppSVG from "../../assets/whatsapp.svg";
import PlaceOrderModal from '../PlaceOrder';

const FloatingIcon = () => {
  const [showButtons, setShowButtons] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleToggle = () => {
    setShowButtons((prev) => !prev);
  };

  const handleOpenModal = () => {
    setModalVisible(true);  
  };

  const handleCloseModal = () => {
    setModalVisible(false); // Hide modal
    window.location.reload()
  };

  return (
    <div className={styles.floating_div}>


      {showButtons && (
        <div className={styles.button_container}>
            <button className={styles.place_order_btn_popup} onClick={handleOpenModal}>
                <p className={styles.place_order_btn_text}>PLACE ORDER</p>
                <PlaceOrderModal isVisible={isModalVisible} onClose={handleCloseModal} />
            </button>
            <button
                className={styles.directBtn}
                onClick={() => window.open("https://wa.me/919550327771", "_blank")}
                >
                <img src={WhatsAppSVG} alt="WhatsApp" />
                DIRECT CONTACT
            </button>
        </div>
      )}
      <div className={styles.floating_button} onClick={handleToggle}>
        <img src={FloatingIconImg}></img>
      </div>
    </div>
  );
};

export default FloatingIcon;