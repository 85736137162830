import React from "react";
import styles from "./AssistedPurchase.module.css";
import RightSvg from "../../assets/right.svg";
import LeftSvg from "../../assets/left.svg";

const steps = [
  {
    step: 1,
    title: "1. PASTE THE PRODUCT LINK ON OUR PLATFORM",
    description:
      "Simply browse & copy the product link from any Indian e-commerce website and paste it into our platform. We'll handle the rest, ensuring a seamless shopping experience for you.",
    videoSrc: "https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q",
  },
  {
    step: 2,
    title: "2. SIGN UP AND PROVIDE CONTACT DETAILS",
    description:
      "Sign up with your details, and provide the necessary contact information. This helps us reach out to you for further instructions, payment confirmation and deliver the packages.",
    videoSrc: "https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q",
  },
  {
    step: 3,
    title: "3. CONNECT FOR PREFERENCES AND PAYMENTS",
    description:
      "We'll connect with you to confirm your product preferences and finalize payment. Your order will be placed as per your specifications.",
    videoSrc: "https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q",
  },
  {
    step: 4,
    title: "4. WE PLACE THE ORDER AND KEEP YOU UPDATED",
    description:
      "We handle the entire ordering process and keep you informed with regular updates on your order's status and delivery timeline.",
    videoSrc: "https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q",
  },
  {
    step: 5,
    title: "5. RECEIVE YOUR ORDER AT YOUR DOORSTEP",
    description:
      "Sit back and relax as we deliver your order directly to your doorstep, ensuring a hassle-free shopping experience.",
    videoSrc: "https://www.youtube.com/embed/0Oacr8VrnNk?si=YzCrNf7pHR2_Qx5Q",
  },
];

const AssistedPurchase = () => {
  return (
    <div className={styles.assisted_page}>
      {steps.map((step, index) => (
        <div key={index} className={styles.assisted_page_wrapper}>
          {step.step % 2 === 0 && step.step !== steps.length && (
            <img src={LeftSvg} alt="Left Arrow" className={styles.arrow_left} />
          )}

          <div
            className={`${styles.assisted_page_step} ${
              index % 2 === 0 ? styles.odd : styles.even
            }`}
          >
            <div className={styles.step_info}>
              <span className={styles.step_number}>STEP {index + 1}</span>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
            <div className={styles.step_video}>
              <iframe
                width="278"
                height="154"
                src={step.videoSrc}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {step.step % 2 !== 0 && step.step !== steps.length && (
            <img
              src={RightSvg}
              alt="Right Arrow"
              className={styles.arrow_right}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default AssistedPurchase;
